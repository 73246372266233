
.modal-content {
    background-color: black;
    background:rgba(0, 0, 0, 0.9);/* give opacity to bg color only */
}

.modal-body > div {
    text-align: center;
}

.modal-body > div > img {
    width: 100%;
    height: 100%;
    max-width: 1024px;
    max-height: 768px;
}

.modal-title {
    color: white;
}

.modal-header .btn-close {
    background-color: white;
}
