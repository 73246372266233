
html body {
	margin: 0;
	padding: 0;
	font-family: 'Courier New', monospace;
	background-color: #abb8c3;
}

nav, .container-fluid {
	max-width: 1280px;
	margin: auto;
}
