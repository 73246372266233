
.navbar-light .navbar-brand, .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
	font-size: 3rem;
	color: #244e66;
}

.imageSliderItem {
	width: 100%;
	background-position: center;
	background-repeat: no-repeat;
	height: 192px;
	margin: auto;
}
