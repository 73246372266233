
.title {
	font-size: 2rem;
	color: #244e66;
	margin-top: 2rem;
}

.card {
	width: 384px;
	margin-top: 2rem;
	cursor: pointer;
}
